<template>
  <layout-vertical>
    <router-view />

    <div
      slot="vertical-menu-header"
      class="d-flex flex-column align-items-center h-100"
    >
      <div class="logo__container">
        <h1
          class="h5 font-medium-5 text-dark mt-1"
          role="button"
          @click="$router.push('/welcome')"
        >
          Toqoo <span class="text-primary">Material</span><br />
        </h1>
        <div
          class="versioning"
          id="show-btn"
          @click="$bvModal.show('modal-versioning')"
        >
          <span class="d-block text-center"
            ><u>Version {{ latestVersion }}</u></span
          >
        </div>
        <ModalVersioning :changelogs="changelogs" />
      </div>
      <b-modal id="modal-maintenance-mode" title="Pemberitahuan" hide-footer>
        <div v-if="maintenanceStatus.length > 0">
          <div>
            <span class="size18 text-capitalize">{{
              maintenanceStatus[0].description
            }}</span>
            <span class="size18"> akan dilakukan pada:</span>
          </div>
          <div>
            {{ maintenanceStatus[0].start_datetime }} -
            {{ maintenanceStatus[0].end_datetime }}
          </div>
        </div>
      </b-modal>

      <b-row class="w-100 p-2">
        <b-col cols="3" class="px-0 text-right">
          <b-avatar variant="info" :src="userData.photo_url" />
        </b-col>
        <b-col cols="8" class="pr-0">
          <div class="user__info">
            <h1 class="mb-0 size14 text-dark text-truncate">
              <!-- Miraz D. -->
              {{ userData.name }}
            </h1>
            <!-- <small class="mb-0 text-dark text-darken-5"> -->
            <span v-for="(role, index) in userRole" :key="index">
              <b-badge pill variant="light-primary">
                {{ role }}
              </b-badge>
              <br />
            </span>
            <!-- </small> -->
          </div>
        </b-col>
        <!-- <b-col
          cols="1"
          class="px-0 text-right d-flex justify-content-end align-items-center"
        >
          <b-navbar-nav>
            <b-nav-item-dropdown
              right
              toggle-class="d-flex align-items-center"
              class="custom__dropdown"
            >
              <template
                #button-content
              >
                <div class="">
                  <b-img
                    :src="imgDot"
                    width="10"
                  />
                </div>
              </template>
              <b-dropdown-item
                v-if="checkPermission('kasir')"
                link-class="d-flex align-items-center"
                @click="startTutorial"
              >
                <feather-icon
                  v-if="!isLoading"
                  size="16"
                  icon="PlayIcon"
                  class="mr-50"
                />
                <b-spinner
                  v-else
                  small
                />
                <span>{{ userData.finish_tutorial === false ? 'Skip Tutorial' : 'Mulai Tutorial' }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="logout"
              >
                <feather-icon
                  size="16"
                  icon="LogOutIcon"
                  class="mr-50"
                />
                <span>Logout</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-col> -->
      </b-row>
      <div
        class="d-flex flex-column w-75 pt-1"
        style="border-top: 1px solid #e4e5ec"
        id="switch--branch"
        v-if="checkPermission('change cabang user')"
      >
        <div class="">
          <b-form-select
            v-model="selected_branch"
            :options="options"
            @change="changeBranchUser()"
          />
        </div>
      </div>
      <div
        class="d-flex w-75 pb-1"
        :class="{ 'mt-1': checkPermission('change cabang user') }"
        style="border-top: 1px solid #e4e5ec"
        @click="logout"
        role="button"
        id="button-logout"
      >
        <div class="d-flex align-items-center mt-1">
          <feather-icon size="18" icon="LogOutIcon" class="mr-50" />
          <h6 class="size14 font-weight-bolder mb-0">Keluar</h6>
        </div>
      </div>
    </div>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import ModalVersioning from "@/components/Versioning/ModalVersioning.vue";

import { mapGetters } from "vuex"; // Import Vuex helper functions

// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import {
  BAvatar,
  BImg,
  BRow,
  BCol,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BSpinner,
  BFormSelect,
  BBadge,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { checkPermission } from "@/auth/utils";
// import { $themeConfig } from '@themeConfig'
// import Navbar from '../components/Navbar.vue'

import imgDot from "@/assets/images/icons/dot-4.png";

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    BAvatar,
    BImg,
    BRow,
    BCol,
    BBadge,
    BNavItemDropdown,
    BDropdownItem,
    BNavbarNav,
    BSpinner,
    BFormSelect,
    ModalVersioning,
    // Navbar,
  },
  setup() {
    return {
      checkPermission,
    };
  },
  computed: {
    ...mapGetters("versioning", ["getChangelogs"]),
  },
  created() {
    this.fetchChangelogs();
  },
  data() {
    return {
      imgDot,
      userData: JSON.parse(localStorage.getItem("userData")),
      userRole: JSON.parse(localStorage.getItem("userRole")),
      jabatan: "",
      isLoading: false,
      selected_branch: "",
      options: [],
      latestVersion: "",
      changelogs: [],
      maintenanceStatus: [],
      testing: true,
      // showCustomizer: $themeConfig.layout.customizer,
    };
  },
  watch: {
    "$store.state.profile.jabatan": function (newName) {
      this.jabatan = newName;
    },
  },
  // beforeMount() {
  //   this.leaving()
  // },
  mounted() {
    this.getCabang();
    if (localStorage.getItem("accessToken")) {
      this.getProfiles();
      this.getMaintenanceStatus();
    }
    // this.selected_branch = this.userData?.branch?.uuid
    this.jabatan = this.$store.state.profile.jabatan;
  },
  methods: {
    async getCabang() {
      this.$store
        .dispatch("cabang/getData", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          this.options = [];
          const datas = result.data.data.data;
          for (const data of datas) {
            this.options.push({
              value: data.uuid,
              text: data.name,
            });
          }
          // this.options = result.data.data.data
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    changeBranchUser() {
      this.$store
        .dispatch("cabang/changeBranch", {
          user_id: this.userData.id,
          payload: {
            _method: "PATCH",
            branch_uuid: this.selected_branch,
          },
        })
        .then((response) => {
          this.getProfiles();
          this.$router.go(0);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    // leaving() {
    //   if (localStorage.getItem('otp_type')) {
    //     this.$router.push('/otp')
    //   }
    // },
    getMaintenanceStatus() {
      this.$store
        .dispatch("profile/getMaintenanceStatus")
        .then((response) => {
          this.maintenanceStatus = response.data.data;
          console.log(this.maintenanceStatus);

          // Check if there's maintenance data and show modal
          if (this.maintenanceStatus.length > 0) {
            this.$bvModal.show("modal-maintenance-mode");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getProfiles() {
      await this.$store
        .dispatch("profile/getProfile")
        .then((response) => {
          const userData = JSON.stringify(response.data.data);
          localStorage.setItem("userData", userData);
          this.userData = response.data.data;
          this.selected_branch = response.data.data.branch.uuid;
          this.$store.commit("profile/SET_JABATAN", response.data.data.jabatan);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async startTutorial() {
      this.isLoading = true;
      await this.$store
        .dispatch("tutorial/postData")
        .then((result) => {
          const userData = JSON.stringify(result.data.data);
          localStorage.setItem("userData", userData);
          // this.$bvModal.show('modal-tutorial')
          this.isLoading = false;
          window.location.reload();
          // this.$router.push({ name: 'cashier.index', query: { _r: Date.now() } })
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Redirect to login page
      this.$router.push({ name: "login" });
    },
    async fetchChangelogs() {
      try {
        await this.$store.dispatch("versioning/fetchData"); // Use the action from the store
        this.changelogs = this.getChangelogs; // Populate the changelogs data from the store

        // Set the latestVersion property to the version number of the first changelog entry if available
        if (this.changelogs.length > 0) {
          this.latestVersion = this.changelogs[0].version_code;
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    },
  },
};
</script>

<style lang="scss">
.b-nav-dropdown.custom__dropdown .dropdown-toggle::after {
  background-image: none !important;
  width: unset;
  height: unset;
}
.logo__container {
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid #f6f7fa;
  background: #f6f7fa;
  line-height: 1px;

  h1 {
    text-align: center;
  }
}
.versioning {
  cursor: pointer;
  color: #7a7f94;
}
</style>
